.tab-switcher {
  display: flex;
  justify-content: left;
  border-bottom: 2px solid #eaecf0 !important;
  width: 100%;
}
.HolderButtons {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.tab-item {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: 3px solid transparent; /* Default state, no active color */
  outline: none;
  font-size: 1em; /* Adjust font size as needed */
}

.tab-item:hover {
  background-color: #f9f9f9; /* Slight background on hover for better UX */
}

.tab-item.active {
  border-bottom-color: #009688; /* Active tab color */
  font-weight: bold;
  color: #009688; /* Active tab text color */
}
