.data{
    background-color: rgb(98, 84, 68);
    color: black;
    font-weight: 800;
    font-size: 15px;
}

.buttons_compta{
    display: flex;
    gap:15px;
    margin: 30px 0px;
}