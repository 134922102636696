.nbrDossier {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
  color: #b4b4b4;
  margin-top: 0px;
}
.titre-dossier {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

/* TablePaginators.css */

.paginator-container {
  background-color: #fff; /* Light gray background */
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.paginator-container .pagination {
  margin-bottom: 0; /* Align pagination with buttons */
}

.paginator-container .button-group .btn {
  margin: 0 0.25rem; /* Spacing between buttons */
}

/* Adjust the primary color as needed */
.paginator-container .btn-primary {
  background-color: #068f7a;
  border-color: #068f7a;
}

/* Adjust the secondary color as needed */
.paginator-container .btn-secondary {
  background-color: #ffffff;
  color: #555555;
  border-color: #cccccc;
}

/* Hover styles */
.paginator-container .btn-secondary:hover {
  background-color: #e9ecef;
}

/* Active and focus styles */
.paginator-container .btn:focus,
.paginator-container .btn:active {
  box-shadow: none; /* Remove Bootstrap's default focus shadow */
}

/* Dropdown styles */
.paginator-container .dropdown-toggle {
  color: #555555;
  background-color: #ffffff;
  border-color: #cccccc;
}

.paginator-container .dropdown-menu {
  min-width: 6rem; /* Ensure a consistent width */
}

/* Adjust this if you add more items to fit the design */
.paginator-container .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

.bkgr {
  content: '';
  height: 20px;
  width: 20px;
  padding: 20px;
  background-color: #068f7a;
  border-radius: 50%;
  opacity: 0.7;
}
.card-transac {
  width: 24%;
}
@media screen and (max-width: 1024px) {
  .card-transac {
    width: 100%;
  }
}

@media print {
  .print-hide {
    display: none !important;
  }
}
