.nbrDossier {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
  color: #b4b4b4;
  margin-top: 0px;
}
.titre-dossier {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}
.search-Permissions:active,
.search-Permissions:focus {
  border: 0px;
  outline: none;
}
.button-permission-search {
  height: 38px;
  font-size: 20px;
  border: 1px solid #efefef;
  background-color: white;
  border-radius: 8px;
  padding: 0px 14px;
  font-family: "Montserrat";
}

/* Modal */

/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  max-width: 450px;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  font-family: "Montserrat";
}

.modal-header {
  font-size: 24px;
  margin-bottom: 10px;
  color: black !important;
  font-weight: 400;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-controll,
.form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.text-danger {
  color: red;
  font-size: 12px;
}

.collaborateurs-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px;
}

.collaborateur-item {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border: 1px solid #eee;
  margin: 5px;
  border-radius: 8px;
}

.collaborateur-item:last-child {
  border-bottom: none;
}

.collaborateur-item:hover {
  background-color: #f0f0f0;
}

.collaborateur-item.selected {
  background-color: #d4fffb;
  border: 1px solid #d4fffb;
}

.collaborateur-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.collaborateur-info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.collaborateur-info p {
  margin: 0;
}

.checkmark {
  margin-left: auto;
  color: green;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
  gap: 6px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-danger {
  background-color: #f44336;
  color: white;
  margin-right: 10px;
}

.btn-primary {
  background-color: #4caf50;
  color: white;
}

.header-icon {
  background-color: #d4fffb;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

#role:active,
#role:focus {
  outline: none;
  border: 0;
}

#role select {
  color: #000000;
}

.collaborateurs-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.collaborateurs-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.collaborateurs-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.collaborateurs-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
