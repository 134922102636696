.arianeFournisseur{
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 600;
    color:#B4B4B4 !important;
  }
  .arianeFournisseurdetail{
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 600;
    color:#000000 !important;

  }
  .breadcrumb{
    margin-bottom: 0 !important;
  }
  .customs{
    border-radius: 31px !important;
    background-color:#fff !important;
    padding: 10px;
    border: solid 50px #DDDDDD;
  }
  .lab{
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #555555 !important;
  }
  .nbrDossier{
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    margin-left: 10px;
    color: #B4B4B4;
    margin-top: 0px;
  }
  .titre-dossier{
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    color: #000000;
  
  }