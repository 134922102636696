.skeleton-container {
    width: 100%;
  }
  
  .skeleton-line {
    height: 30px; /* Hauteur souhaitée pour chaque ligne */
    width: 100%;
    background-color: #f0f0f0; /* Couleur de fond du squelette */
    margin-bottom: 6px; /* Marge entre chaque ligne */
    animation: skeleton-wave 1.5s infinite; /* Animation de l'effet de vague */
  }
  
  @keyframes skeleton-wave {
    0%, 60%, 100% {
      background-color: #f0f0f0; /* Couleur de fond initiale */
    }
    30% {
      background-color: #e0e0e0; /* Couleur de fond de la vague */
    }
  }
  