/* Cover.css */

/* Default styles for larger screens */
.header-title {
  padding: 15px;
  flex: 1;
}

.header-title img {
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}

/* Media query for screens with a width of less than 700px */
@media (max-width: 700px) {
  .header-title {
    /* Adjust padding or other styles as needed for smaller screens */
    padding: 10px;
  }
}

/*  */

.profiles {
  position: absolute;
  bottom: -100px;
  left: 30px;
  transform: translateY(-50%);
}

/* When screen width is 1024px or less, center the profile image */
@media (max-width: 1024px) {
  .profiles {
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
  }
}
