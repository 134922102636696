.global{
    background-color: 'red ';
}

  /* src/styles.css */
  .folder-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* Trois colonnes, ajustez selon vos besoins */
    gap: 1px; /* Espacement entre les éléments */
  }

.folder-list-item {
  background-image: url('Frame.png');
  background-size: cover;
  padding: 10px;
  border-radius: 5px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
} 

/* src/styles.css */
.folder-detail {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.folder-name {
  margin-top: 100px; /* Ajouter une marge entre l'image et le nom du dossier */
}
.nbrDossier{
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
  color: #B4B4B4;
  margin-top: -3px;
}
.titre-dossier{
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  color: #000000;

}
/* .selected-dossier {
  background-color: #CCE8FF; 
} */
.carte:hover{
  transform: scale(1.05);
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06); */
  cursor: pointer;
}
