.nbrDossier{
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    margin-left: 10px;
    color: #B4B4B4;
    margin-top: 0px;
  }
  .titre-dossier{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 36px;
    font-weight: 600;
    color: #000000;
    font-weight: bold;
  
  }