.nbrDossier {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
  color: #b4b4b4;
  margin-top: 0px;
}
.titre-dossier {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

/* TablePaginators.css */

.paginator-container {
  background-color: #fff; /* Light gray background */
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.paginator-container .pagination {
  margin-bottom: 0; /* Align pagination with buttons */
}

.paginator-container .button-group .btn {
  margin: 0 0.25rem; /* Spacing between buttons */
}

/* Adjust the primary color as needed */
.paginator-container .btn-primary {
  background-color: #068f7a;
  border-color: #068f7a;
}

/* Adjust the secondary color as needed */
.paginator-container .btn-secondary {
  background-color: #ffffff;
  color: #555555;
  border-color: #cccccc;
}

/* Hover styles */
.paginator-container .btn-secondary:hover {
  background-color: #e9ecef;
}

/* Active and focus styles */
.paginator-container .btn:focus,
.paginator-container .btn:active {
  box-shadow: none; /* Remove Bootstrap's default focus shadow */
}

/* Dropdown styles */
.paginator-container .dropdown-toggle {
  color: #555555;
  background-color: #ffffff;
  border-color: #cccccc;
}

.paginator-container .dropdown-menu {
  min-width: 6rem; /* Ensure a consistent width */
}

/* Adjust this if you add more items to fit the design */
.paginator-container .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}
.button-btn {
  border-radius: 31px;
  color: #ffffff;
  background-color: #009688;
  border: none;
}
.custom-rounded {
  border-radius: 31px !important;
  background-color: #f3f4f6 !important;
  padding: 10px;
}

.containeDetails {
  width: 70%;
}
.pieceContainer {
  width: 30%;
}
@media screen and (max-width: 765px) {
  .containeDetails {
    width: 100%;
  }
  .pieceContainer {
    width: 100%;
  }
}

/* Details */

.depense-detail-container {
  background-color: #fff; /* White background */
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin: auto;
}

.form-layout {
  display: flex;
  flex-direction: column;
}

.form-section-title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333; /* Dark grey color for text */
}

.input-row {
  display: flex;
  margin-bottom: 15px;
}

.custom-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
}

.custom-input:focus {
  border-color: #f06;
  outline: none;
}

.form-navigation-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.prev-button,
.next-button {
  border: none;
  padding: 10px 20px;
  border-radius: 20px; /* Fully rounded corners for buttons */
  font-size: 16px;
  cursor: pointer;
  background-color: #068f7a; /* Pink background for buttons */
  color: white; /* White text for buttons */
}

.prev-button {
  border: 1px solid #068f7a;
  background-color: white;
  color: #068f7a;
}

.prev-button:hover {
  background-color: #068f7a;
  color: white;
}
.next-button:hover {
  background-color: #066859;
}

/* Add responsiveness if needed */
@media (max-width: 768px) {
  .input-row {
    flex-direction: column;
  }

  .custom-input {
    width: 100%; /* Full width inputs on smaller screens */
    margin-bottom: 15px; /* Add space between stacked inputs */
  }
}
