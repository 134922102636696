.enregistrer{
   
    box-shadow: 3px 3px black;
    transition: all 0.3s ease-in-out;
}

.enregistrer:hover{
    cursor: pointer;
    opacity: 0.7;
    transform: scale(1.05);
}

.show{
    display: block;
}

.cacher{
    display: none;
}