/* Modal.css */
.modal-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 1000;
    overflow-y: scroll;
  }
  
  .modal-content-cabinet {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 100%; /* Adjusted for responsive width */
    max-width: 900px; /* Max width for larger screens */
    margin-left: 20%;
    margin-top: 20%;
  }
  
  .close-btn {
    position: absolute; /* Positioning close button */
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #333; /* Assuming the close button is an 'X' icon */
    cursor: pointer;
  }
  
  .modal-header {
    font-size: 24px;
    color: #068f7a;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  label .form-label {
    color: #068f7a;
  }
  
  .form-controll {
    width: 100%;
    padding: 7px;
    border: 1px solid #ddd;
    border-radius: 50rem;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .text-danger {
    color: #d9534f;
    margin-top: 5px;
    font-size: 14px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between; /* Adjusted for spacing between buttons */
    margin-top: 20px;
    gap: 10px;
  }
  
  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px; /* Spacing between buttons */
  }
  
  .cancel-btn {
    background-color: #d9534f;
    color: white;
  }
  
  .submit-btn {
    background-color: #5cb85c;
    color: white;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .modal-content-cabinet {
      width: 80%; /* Full width on smaller screens */
    }
  }
  