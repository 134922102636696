.upload-area {
  border: 2px dashed #ccc;
  border-radius: 4px;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.file-preview {
  max-width: 100%;
  max-height: 250px; /* Adjust as needed */
}

.file-name {
  word-break: break-all;
}

.hidden-file-input {
  display: none;
}

/* Additional styling for the upload text and icon */
.upload-text {
  color: #6c757d;
  margin-top: 15px;
}

.upload-icon {
  /* Your icon styling here */
}
