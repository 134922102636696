.modifier{
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 8px;
    -webkit-box-shadow: -1px 1px 95px -22px rgba(0,0,0,0.82);
    -moz-box-shadow: -1px 1px 95px -22px rgba(0,0,0,0.82);
    box-shadow: -1px 1px 95px -22px rgba(0,0,0,0.82);
    transition: all 0.3s ease-in-out;
    color: black;
    background-color: rgba(207, 149, 57, 0.3);
    padding: 10px;
    margin-top: 20px;
}

.modifier:hover{
    transform: scale(1.05);
    cursor: pointer;
    opacity: 0.5;
    font-weight: bold;
}