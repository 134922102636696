.textaria {
  margin-top: 20px;
}
.imageinput {
  border-radius: '31px';
}
.image-preview {
  height: 200px;
  width: 200px;
}

.prev-button,
.next-button {
  border: none;
  padding: 10px 20px;
  border-radius: 20px; /* Fully rounded corners for buttons */
  font-size: 16px;
  cursor: pointer;
  background-color: #068f7a; /* Pink background for buttons */
  color: white; /* White text for buttons */
}

.prev-button {
  border: 1px solid #068f7a;
  background-color: white;
  color: #068f7a;
}

.prev-button:hover {
  background-color: #068f7a;
  color: white;
}
.next-button:hover {
  background-color: #066859;
}
