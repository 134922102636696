p{
    margin: 0px;
}
.cardcss {
    background-color: #fff;
    margin: auto;
    border-radius: 10px;
    position: relative;
    margin-bottom: 30px;
}
.montant-card{
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16.98px;
    color:'#fff' !important;
  }
  /* .cardStyle{
    background-color: "#FFF";
    padding: "10px";
    border-radius: "20px";
    box-shadow: "0 4px 8px rgba(0, 0, 0, 0.25)";
    width: "325px";
    height: "120px";
    margin-bottom:"15px";
  } */
  .cartes:hover{
    transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
    cursor: pointer;
  }