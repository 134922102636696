.prev-button,
.next-button,
.add-button,
.add-button-green,
.cancel-button {
  border: none;
  padding: 10px 20px;
  border-radius: 20px; /* Fully rounded corners for buttons */
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  background-color: #068f7a; /* Pink background for buttons */
  color: white; /* White text for buttons */
  font-family: "Montserrat";
}

.prev-button {
  border: 1px solid #068f7a;
  background-color: white;
  color: #068f7a;
}
.add-button {
  border: 1px solid #aeaeae;
  background-color: white;
  color: #000;
}
.add-button-green {
  border: 1px solid #068f7a;
  background-color: #068f7a;
  color: white;
}
.cancel-button {
  border: 1px solid #c61e1e;
  background-color: #c61e1e;
  color: white;
}

.prev-button:hover {
  background-color: #068f7a;
  color: white;
}
.next-button:hover {
  background-color: #066859;
}
.add-button:hover {
  background-color: #068f7a;
  color: white;
}
.add-button-green:hover {
  background-color: #066859;
}
.cancel-button:hover {
  background-color: #901616;
}
